import Tinashe2 from "../pictures/robotcar.png";
import Tinashe3 from "../pictures/2.JPEG";
import Tinashe4 from "../pictures/Android Studio1.png";
import Tinashe5 from "../pictures/Visual Studio2.png";
import Tinashe6 from "../pictures/4.JPEG";
import Tinashe7 from "../pictures/mosquito.jpg";
import Tinashe8 from "../pictures/01WintrProject.png";
import Tinashe9 from "../pictures/02ModbusSim.png";
import Tinashe10 from "../pictures/03welcome-to-react.png";
import Tinashe11 from "../pictures/04react.png";
import Tinashe12 from "../pictures/05Bokpoort1.jpeg";
import Tinashe13 from "../pictures/06Bokpoort2.jpeg";
import Tinashe14 from "../pictures/07FES1.jpeg";
import Tinashe15 from "../pictures/08FES2.jpeg";
import Tinashe16 from "../pictures/Tinashe16.jpeg";
import Tinashe17 from "../pictures/Tinashe17.jpeg";
import ProjectInfo from "./ProjectsInfo";
import "./ProjectStyles.css";

const Project = () => {
  return (
    <div className="body">
      <h1>Overview</h1>
      <p>
        Welcome to my projects page, where you can get a glimpse into some of
        the exciting ventures I've been fortunate enough to be a part of. From
        conceptualization to execution, I take great pride in every project I
        work on, and I'm always eager to tackle new challenges. Below you'll
        find a selection of my recent projects, each one unique in its goals,
        scope, and approach. So take a look around, and feel free to reach out
        if you'd like to know more about my work or have a project you'd like me
        to be a part of.
      </p>

      <ProjectInfo
        className="first-body"
        heading="Robot Car"
        text="The robot care was a group project and I was the one in charge of the programming of the car. The robot car was designed to follow a black line. It was also capable of sensing the colours red and blue. The car would turn left when a blue colour is detected on the track and turn right when it detects a red colour. Besides programming the car, I was also involved in wiring the circuit and calibrating the sensors so that I know what RGB Colour values I was working with in the code."
        img1={Tinashe2}
        img2={Tinashe3}
      />

      <ProjectInfo
        className="first-body-switch"
        heading="Google Maps"
        text="This is more of a personalised project that am working on and I have been working on it since mid-2023 and its’s been slow in coming. Am hoping that this year I will be able to finish it and get it up in running."
        img1={Tinashe4}
        img2={Tinashe5}
      />
      <ProjectInfo
        className="first-body"
        heading="Mosquito Trapping Device"
        text="This was s group project and I was in charge of the programming subsystem. I was solely responsible for coding the whole device using Adriano. The device was supposed to lure mosquitoes into a trap for scientific research, hence we were tasked to create the device that was capable of luring and trapping. The luring required the use of a buzzer that was supposed to produce a specific frequency that attracts mosquitoes and an infrared sensor that is capable of detecting and counting the number of mosquitoes that are in the container."
        img1={Tinashe6}
        img2={Tinashe7}
      />

      <ProjectInfo
        className="first-body-switch"
        heading="Working With PLC's at CAMBRAS"
        text="At CAMBRAS, I programmed PLCs and led a project using SCADA Wintr to develop a pump monitoring system. I seamlessly integrated SCADA with PLCs, enabling real-time monitoring of pump performance and proactive intervention to prevent downtime. My expertise in PLC programming and SCADA implementation ensured efficient and reliable industrial automation solutions."
        img1={Tinashe8}
        img2={Tinashe9}
      />
      <ProjectInfo
        className="first-body"
        heading="Full-Stack Development: React Apps"
        text="As a developer, I work with React Native alongside other technologies. While React Native isn't my sole focus, I utilize it effectively to build cross-platform mobile applications. My expertise lies in leveraging React Native's features like component-based architecture and native module integration to deliver efficient and seamless user experiences across iOS and Android platforms. Additionally, I stay updated on various technologies and frameworks to ensure I utilize the best tools for each project's requirements."
        img1={Tinashe10}
        img2={Tinashe11}
      />

      <ProjectInfo
        className="first-body-switch"
        heading="Bokpoort CSP Power Plant"
        text="At CAMBRAS, I contributed to a pioneering project installing and monitoring the Active Tracking System at Bokpoort CSP Power Plant. Leveraging advanced algorithms and sensor technology, we optimized solar panel positioning for maximum efficiency. My role involved seamless integration, real-time monitoring, and collaboration on algorithm fine-tuning, resulting in significant enhancements to solar harvesting performance."
        img1={Tinashe12}
        img2={Tinashe13}
      />
      <ProjectInfo
        className="first-body"
        heading="Working at Flexible Electronics"
        text="
        At Flexible Electron, my role allowed me to specialize in several key areas within electrical engineering, with a primary focus on constructing Printed Circuit Boards (PCBs) using Attenua. This involved working with Attenua, a software tool commonly used in the design and layout of PCBs. Utilizing Attenua, I was responsible for translating schematic diagrams and technical specifications into functional PCB layouts, ensuring the proper placement of components and signal routing for optimal performance.
        
        My experience in both PCB construction and electronics repair underscores my comprehensive understanding of electrical engineering principles and practices. From designing and prototyping new circuitry to diagnosing and resolving issues in existing systems, I am adept at navigating the entire lifecycle of electronic products. This hands-on experience has equipped me with the practical skills and problem-solving abilities necessary to excel in the field of electrical engineering."
        img1={Tinashe14}
        img2={Tinashe15}
      />
      <ProjectInfo
        className="first-body-switch"
        heading="Working at TRM Supplies"
        text="In my role, I specialized in handling the entire lifecycle of sheet metal machines and CNC-controlled equipment. This included sourcing, importing, installing, and providing ongoing support and repair services for new machines. Additionally, I was involved in refurbishing, retrofitting, and repairing existing CNC machines. This multifaceted experience equipped me with a comprehensive skill set to effectively manage industrial machinery and deliver tailored solutions to our clients"
        img1={Tinashe16}
        img2={Tinashe17}
      />
    </div>
  );
};

export default Project;
