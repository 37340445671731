import Project from "../components/Projects";
import Tina from "../components/Tina";
import Tinashe1 from "../pictures/3.JPEG";

function Projects() {
  return (
    <>
      <Tina cName="tina-smaller" tinaImg={Tinashe1} title="Projects" />
      <Project />
    </>
  );
}

export default Projects;
