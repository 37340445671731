//import "./styles.css";
import React from "react";
import AppRoutes from "./routes";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

const AppContainer = () => {
  return (
    <React.Fragment>
      <Navbar />
      <AppRoutes />
      <Footer />
    </React.Fragment>
  );
};

export default AppContainer;
