import { Component } from "react";
import "./NavbarStyles.css";
import { MenuItems } from "./MenuItems";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

class Navbar extends Component {
  state = { clicked: false };

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  handleItemClick = () => {
    this.setState({ clicked: false }); // Reset clicked state to close the dropdown
  };

  render() {
    return (
      <nav className="NavbarItems">
        <h1 className="navbar-logo">Portfolio</h1>

        <div className="menu-icons" onClick={this.handleClick}>
          {this.state.clicked ? <FaTimes /> : <FaBars />}
        </div>

        <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
          {MenuItems.map((item, index) => (
            <li key={index}>
              <Link
                className={item.cName}
                to={item.url}
                onClick={this.handleItemClick} // Call handleItemClick on link click
              >
                {item.icon && (
                  <span
                    style={{
                      paddingRight: "5px", // Adjust padding-right value as needed
                      paddingLeft: "10px", // Adjust padding-left value as needed
                    }}
                  >
                    {item.icon}
                  </span>
                )}
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    );
  }
}

export default Navbar;
