import { AiFillHome } from "react-icons/ai";
import { FaInfoCircle } from "react-icons/fa";
import { FaBriefcase } from "react-icons/fa";
import { FaAddressBook } from "react-icons/fa";

export const MenuItems = [
  {
    title: "Home",
    url: "/",
    cName: "nav-links",
    icon: <AiFillHome />,
  },
  {
    title: "About",
    url: "/about",
    cName: "nav-links",
    icon: <FaInfoCircle />,
  },
  {
    title: "Projects",
    url: "/projects",
    cName: "nav-links",
    icon: <FaBriefcase />,
  },
  {
    title: "Contact",
    url: "/contact",
    cName: "nav-links",
    icon: <FaAddressBook />,
  },
];
