import Tinashe2 from "../pictures/paintball.jpg";
import Tinashe3 from "../pictures/paintball2.jpg";
import Tinashe4 from "../pictures/friends.jpg";
import Tinashe5 from "../pictures/friends2.jpg";
import Tinashe6 from "../pictures/family.jpg";
import Tinashe7 from "../pictures/family2.jpg";
import AboutInfo from "./AboutsInfo";
import "./AboutStyles.css";

const About = () => {
  return (
    <div className="body">
      <h1>The Fun Side</h1>
      <p></p>

      <AboutInfo
        className="first-body"
        heading="Paintballing"
        text="Looking for a team player who knows how to handle high-pressure situations? 
        Look no further than me, the paintball master! I recently led my squad to victory in a grueling game of paintball, dodging enemy fire and strategizing on the fly. 
        Armed with nothing but a trusty marker and nerves of steel, I emerged victorious, covered head to toe in a kaleidoscope of colors. 
        If you want someone who can handle a challenge and come out on top, I'm your go-to guy. 
        Just don't forget to bring the goggles!"
        img1={Tinashe2}
        img2={Tinashe3}
      />

      <AboutInfo
        className="first-body-switch"
        heading="The Nerd Crew"
        text="When it comes to teamwork, there's no group quite like us! 
        We five musketeers have been through it all together, from all-nighters in the library to epic parties on the weekends. 
        We've got each other's backs no matter what, and we're not afraid to work hard and play harder. 
        In fact, we're so tight-knit that we practically finish each other's sentences (and snacks). So if you're looking for a team that's more like a family, look no further than us! Just be prepared for some seriously awesome group high-fives."
        img1={Tinashe4}
        img2={Tinashe5}
      />
      <AboutInfo
        className="first-body"
        heading="Values"
        text="They say that behind every successful person is a loving family, and I am no exception. 
        This snapshot captures just a glimpse of the amazing support system that has been with me every step of the way. 
        My family is the heart and soul of who I am, and their unwavering love and encouragement has helped me achieve my goals time and time again. 
        From my parents' sage advice to my siblings' playful banter, they keep me grounded and motivated to be the best version of myself. 
        So when you choose me, you're not just getting an individual, you're getting a whole team of dedicated supporters. 
        And that's something that money just can't buy."
        img1={Tinashe6}
        img2={Tinashe7}
      />
    </div>
  );
};

export default About;
