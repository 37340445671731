import React from "react";
import Tina from "../components/Tina";
import Tinashe1 from "../pictures/1.JPG";

function Contact() {
  return (
    <>
      <Tina cName="tina-smaller" tinaImg={Tinashe1} title="" />
    </>
  );
}

export default Contact;
